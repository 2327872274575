<template>
    <div class="">
        <div class="form-data mentor">
            <div class="title">멘토알림</div>
            <div class="value">
                <el-radio-group v-model="hasMentor" disabled>
                    <el-radio :label="1">사용</el-radio>
                    <el-radio :label="0">사용안함</el-radio>
                </el-radio-group>
                <div class="list">
                    <div v-for="(mentor, index) in mentorList" :key="`selMentor${index}`" class="c_mentor">
                        <div class="profile">
                            <img :src="returnProfileImage(mentor)">
                        </div>
                        <div class="info">
                            <div class="i_top">
                                {{mentor.mb_name}}
                                <span>{{mentor.mb_job}}</span>
                            </div>
                            <div class="company">{{mentor.mb_belong}}</div>
                        </div>
                    </div>
                    <div class="caption">지정된 멘토는 변경이 불가능합니다</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import BoardValidator from "@/mixins/validators/BoardValidator";
    import util from "@/mixins/util";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "BoardWriteViewMentorLayout",
        mixins: [BoardValidator, imageOption],
        inject: ['boardWriteSetData'],
        props: {
            boardData: {
                default: {}
            },
        },
        data() {
            return {
                hasMentor: 0,
                mentorList: [],
            }
        },
        computed: {},
        mounted() {
            this.initData();
        },
        methods: {
            initData() {
                if(!util.isEmpty(this.boardData) && this.boardData.hasOwnProperty('Mentors') && this.boardData.Mentors.length > 0) {
                    this.hasMentor = 1;
                    this.mentorList = this.boardData.Mentors;
                }
            },
            returnProfileImage(member) {
                return member.hasOwnProperty('Profile') && member.Profile.length > 0 ? `${member.Profile[0].org_url}?${this.setImageOptions(70, 70, 'png')}` : ''
            },
        },
    }
</script>

<style scoped>

</style>